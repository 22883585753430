import { Box, styled, useMediaQuery } from "@mui/material";

import "./navList.scss";

export default function NavList() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems={isSmallScreen ? "flex-start" : "center"}
      className="navList font-20"
    >
      <a href="#casino" className="navItem">
        CASINO
      </a>
      <a href="#tokenomics" className="navItem">
        TOKENOMICS
      </a>
      <a href="#roadmap" className="navItem">
        ROADMAP
      </a>
      <a href="https://bitgames.gitbook.io/" target="_blank" className="navItem">
        DOCS
      </a>
    </Box>
  );
}
