import { Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import NavList from "./navList";
import { ReactComponent as Close } from "../../assets/images/close.svg";

const DrawerBox = styled(Drawer)`
  .MuiDrawer-paper {
    background: #8a0000;
    width: 90vw;
  }
  a {
    text-decoration: none;
  }
  .close {
    margin-top: 26px;
    margin-left: 16px;
  }
`;

export const DrawerContainer = ({ showDrawer, toggleDrawer }: any) => {
  return (
    <DrawerBox anchor={"left"} open={showDrawer} onClose={toggleDrawer}>
      <Close width={24} height={24} className="close" onClick={toggleDrawer} />
      <NavList />
    </DrawerBox>
  );
};
