import { Box, Link, useMediaQuery, Drawer, SvgIcon } from "@mui/material";

import "./header.scss";
import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import Menu from "../../assets/images/menu.svg";
import { ConnectBtnBox } from "../Button/ConnectBtnBox";
import NavList from "./navList";
import { DrawerContainer } from "./DrawerContainer";
import { toastInfo } from "src/helpers/toastHelpers";

export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isSmallScreen2 = useMediaQuery("(max-width: 1080px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <div className="header-view">
      <Box display="flex" alignItems="center" justifyContent="space-between" className="header">
        <Box display="flex" alignItems="center">
          {isSmallScreen2 && <img src={Menu} className="mr-16 pointer" onClick={toggleDrawer} />}
          <Link component={NavLink} to="/" className="logo">
            <img src={Logo} height={48} />
          </Link>
        </Box>
        <Box display="flex" alignItems="center">
          {!isSmallScreen2 && <NavList />}
          <Box ml={isSmallScreen ? "28px" : "56px"}>
            {/* <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <ConnectBtnBox onClick={openConnectModal}>
                            {isVerySmallScreen ? "CONNECT" : "CONNECT WALLET"}
                          </ConnectBtnBox>
                        );
                      }

                      if (chain.unsupported) {
                        return <ConnectBtnBox onClick={openChainModal}>Wrong network</ConnectBtnBox>;
                      }

                      return (
                        <div style={{ display: "flex", gap: 12 }}>
                          <ConnectBtnBox onClick={openChainModal} style={{ display: "flex", alignItems: "center" }}>
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 24,
                                  height: 24,
                                  borderRadius: 999,
                                  overflow: "hidden",
                                  marginRight: 4,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? "Chain icon"}
                                    src={chain.iconUrl}
                                    style={{ width: 24, height: 24 }}
                                  />
                                )}
                              </div>
                            )}
                            <div className="flex-1">{chain.name}</div>
                          </ConnectBtnBox>
                          <ConnectBtnBox onClick={openAccountModal} type="button">
                            {account.displayName}
                            {account.displayBalance ? ` (${account.displayBalance})` : ""}
                          </ConnectBtnBox>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom> */}
            <ConnectBtnBox>Launch APP</ConnectBtnBox>
          </Box>
        </Box>
      </Box>
      <DrawerContainer showDrawer={showDrawer} toggleDrawer={toggleDrawer} />
    </div>
  );
}
