export enum SupportedChainId {
  // ARBITRUM_ONE = 42161,
  // ARBITRUM_GOERLI = 421613,

  // BSC_MAINNET = 56,
  BST_TESTNET = 97,
}

export const CHAIN_IDS_TO_NAMES = {
  // [SupportedChainId.ARBITRUM_ONE]: "arbitrum",
  // [SupportedChainId.ARBITRUM_GOERLI]: "arbitrum_goerli",
  // [SupportedChainId.BSC_MAINNET]: "Binance Smart Chain Mainnet",
  [SupportedChainId.BST_TESTNET]: "Binance Smart Chain Testnet",
};

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  id => typeof id === "number",
) as SupportedChainId[];

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId];
}
