import ethers, { providers, Wallet } from "ethers";
import { useMemo } from "react";
import { usePublicClient } from "wagmi";
import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";
import { useNetwork, useAccount } from "wagmi";
import { chains as defaultChains } from "../configs/wagmiConfig";
import { useWeb3React } from "web3-react-core";
const { JsonRpcProvider } = providers;
export const useReact = () => {
  const { chain, chains } = useNetwork();
  const { address } = useAccount();
  const { library } = useWeb3React();

  return useMemo(() => {
    if (!chain) {
      const provider = new JsonRpcProvider(defaultChains[0].rpcUrls.default.http[0]);
      const wallt = new Wallet("c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5", provider);
      const chainId = defaultChains[0].id;
      const account = wallt.address;
      return { provider, account, chainId };
    }
    // return { provider: library, account: address, chainId: chain.id };
    const provider = new JsonRpcProvider(chain.rpcUrls.default.http[0]);
    return { provider, account: address, chainId: chain.id };
  }, [chain, chains, address]);
};
