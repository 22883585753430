import LEMONMILKWOFF from "../assets/fonts/LEMONMILK.woff";
import GAMEROBOTdWOFF from "../assets/fonts/GAME_ROBOT.woff";

const GAMEROBOT = {
  fontFamily: "GAME ROBOT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('GAME ROBOT'),
		local('GAME_ROBOT'),
		url(${GAMEROBOTdWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const LEMONMILK = {
  fontFamily: "LEMON MILK",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('LEMONMILK'),
		local('LEMONMILK-Regular'),
		url(${LEMONMILKWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [LEMONMILK, GAMEROBOT];

export default fonts;
