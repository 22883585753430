import { Box, Link, useMediaQuery } from "@mui/material";

import "./footer.scss";
import { ReactComponent as Telegram } from "../../assets/images/links/telegram.svg";
import { ReactComponent as Discord } from "../../assets/images/links/discord.svg";
import { ReactComponent as Reddit } from "../../assets/images/links/reddit.svg";
import { ReactComponent as Twitter } from "../../assets/images/links/twitter.svg";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  return (
    <div className="footer-view">
      <Box display="grid" justifyContent="center" className="footer font-16 font-weight-b">
        <Box display="flex" alignItems="center">
          <div className="dots"></div>
          <div className="text ml-8 flex-1">Provably fair technology</div>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="dots"></div>
          <div className="text ml-8 flex-1">Publicly available information</div>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="dots"></div>
          <div className="text ml-8 flex-1">Blockchain-based transaction verification</div>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="dots"></div>
          <div className="text ml-8 flex-1">Clear and transparent rules</div>
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" className="links">
        <a href="https://twitter.com/BitGamesCasino" target="_blank">
          <Twitter className="twitter" />
        </a>
        <a href="https://discord.com/invite/7fXKy9XZaN" target="_blank">
          <Discord className="discord" />
        </a>
        <a href="https://www.reddit.com/user/Bitgamesmgmt" target="_blank">
          <Reddit className="reddit" />
        </a>
        <a href="https://t.me/BitGamesCasino" target="_blank">
          <Telegram className="telegram" />
        </a>
      </Box>
    </div>
  );
}
