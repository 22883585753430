import { Box } from "@mui/material";
import "./loading.scss";

export default function LoadingSplash() {
  return (
    <main>
      <div class="dice-container">
        <div class="dice">
          <div class="front">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="back">
            <span></span>
          </div>
          <div class="right">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="left">
            <span></span>
            <span></span>
          </div>
          <div class="top">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="bottom">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </main>
  );
}
