import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ConnectBtnBox = styled(Button)({
  height: "50px",
  maxHeight: "50px",
  color: "#8A0000",
  fontSize: "20px",
  fontFamily: "LEMON MILK",
  textTransform: "uppercase",
  background: "#FFFFFF",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  border: "none",
  borderRadius: "25px",
  padding: "0 16px",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    color: "rgba(255, 255, 255, 0.54)",
  },
});
